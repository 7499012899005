import { useCustomerQuery } from "@graphcommerce/magento-customer";
import { Trans } from "@lingui/react";
import { Button } from "@mui/material";
import Link from "next/link";
import { useContext } from "react";
import { ModalContext } from "../../context/context";
import { customerDocument } from "../../graphql/login/currentCustomerData.gql";
import AuthForms from "../AuthForms";
import { SignOutForm } from "../AuthForms/SignOutForm";

export default function MobileLoginBtns() {
  const customer = useCustomerQuery(customerDocument,{fetchPolicy:'network-only'});
  const useModal = useContext(ModalContext);

  const firstname = `${customer?.data?.customer?.firstname} ${customer?.data?.customer?.lastname}`


  return (
    <div className='flex items-center justify-between gap-1 text-white'>
      {customer?.data?.customer ?
        <>
          <Button
            className='transition text-primary-500 hover:text-black no-underline text-base !px-0 !bg-transparent font-medium !max-w-fit !min-w-fit'
          >
            <Link href="/account"
              className='text-white transition no-underline'>
              {firstname}
            </Link>
          </Button>
          <span className='text-default-600'> - </span>
          {/* <SignOutForm> */}
          {/* <Button variant='text' type='submit'
          >
            <Trans id="Sign out" />
          </Button> */}

          {/* </SignOutForm> */}
        </>
        :

        <>
          <Link href="javascript:void(0)" className=' text-sm font-bold transition no-underline'><Trans id="Log in" /></Link>
          <span> | </span>
          <Link href="javascript:void(0)"
            onClick={() => useModal.set && useModal?.set({ content: <AuthForms activeForm='signup' />, open: true, title: '', position: 'center', width: 'max-w-sm' })}

            className='text-sm font-bold transitio no-underline'> <Trans id="Register" /></Link>
        </>}

    </div>
  )
}